// This file is being required on top of application.js
// Frontend dependencies e.g. jquery will not be accessible from here

window.StoreConnect = window.StoreConnect || {};
window.StoreConnect.configure = function (ENV) {

  function initBugsnag(ENV) {
    const bugsnagSrc = "//d2wy8f7a9ursnm.cloudfront.net/v6/bugsnag.min.js"
    const script = document.createElement('script');
    script.src = bugsnagSrc;

    script.onload = function () {
      if (ENV.BUGSNAG_KEY) {
        // Attaching to window so we can easily debug, and test as well
        window.bugsnagClient = bugsnag({
          apiKey: ENV.BUGSNAG_KEY,
          appVersion: ENV.STORE_CONNECT_VERSION,
          notifyReleaseStages: ['production', 'staging'],
          releaseStage: ENV.BUGSNAG_RELEASE_STAGE,
          appType: 'client'
        });

      } else {
        throw new Error("Failed to configure Bugsnag, missing BUGSNAG_KEY");
      }
    };

    // Trigger to load
    document.head.appendChild(script);
  }

  initBugsnag(ENV);
};
